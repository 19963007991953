












































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() checkedUsersList!: Object
  @Prop() selectAllPicklrs!: boolean
  @Prop() picklrFilters!: Object
  public message: string = ''
  public submittingForm = false
  public submitButtonText = 'SEND'

  sendMsgToPicklrs () {
    this.submittingForm = true
    this.submitButtonText = 'SENDING'
    this.$validator.validateAll('send-message').then((result) => {
      if (result) {
        let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
        let payload = { message: this.message, 'userIds': this.checkedUsersList, 'selected_all': this.selectAllPicklrs, 'filters': this.picklrFilters }
        this.$store.dispatch('sendMsgToPicklrs', payload).then((response) => {
          this.$notify({ text: response.body.message, type: 'success' })
          this.$emit('messageSent', 'message sent successfully')
          Object.assign(this.$data, (this.$options.data as any).apply(this))
          this.$nextTick()
            .then(() => {
              this.$validator.errors.clear('send-message')
            })

          popupCloseButton.click()
          this.submittingForm = false
          this.submitButtonText = 'SEND'
        }, response => this.$notify({ text: 'Can not send message', type: 'error' }))
      } else if (this.$validator.errors.has('send-message.message')) {
        this.$notify({ text: this.$validator.errors.first('send-message.message'), type: 'error' })
        this.submittingForm = false
        this.submitButtonText = 'SEND'
      }
    })
  }
}
